import { BookText, CodeSquare, HomeIcon, UserRound, Linkedin, Twitter, Rss, Twitch, Youtube, Crop, Pencil, Computer, Book, Rocket, Speech, Camera, CodeXml, Instagram, Video,TabletSmartphone } from "lucide-react";
import React from "react";

export const socialNetworks = [
    {
        id: 1,
        logo: <Instagram size={30} strokeWidth={1} />,
        src: "https://www.instagram.com/momdel_app/",
    },
    {
        id: 2,
        logo: <Linkedin size={30} strokeWidth={1} />,
        src: "https://www.linkedin.com/company/momdel-aplicaciones/",
    },
    // {
    //     id: 3,
    //     logo: <Twitter size={30} strokeWidth={1} />,
    //     src: "#!",
    // },
    // {
    //     id: 4,
    //     logo: <Rss size={30} strokeWidth={1} />,
    //     src: "#!",
    // },
    // {
    //     id: 5,
    //     logo: <Twitch size={30} strokeWidth={1} />,
    //     src: "#!",
    // },
];



export const itemsNavbar = [
    {
        id: 1,
        title: "Home",
        icon: <HomeIcon size={25} color="#fff" strokeWidth={1} />,
        link: "/",
    },
    {
        id: 2,
        title: "User",
        icon: <UserRound size={25} color="#fff" strokeWidth={1} />,
        link: "/portafolio",
    },
    {
        id: 3,
        title: "Book",
        icon: <BookText size={25} color="#fff" strokeWidth={1} />,
        link: "/servicios",
    },
    {
        id: 4,
        title: "Target",
        icon: <CodeSquare size={25} color="#fff" strokeWidth={1} />,
        link: "/sobreMi",
    },
    {
        id: 5,
        title: "Home",
        icon: <Speech size={25} color="#fff" strokeWidth={1} />,
        link: "/testimonios",
    },
];

export const dataAboutPage = [
    {
        id: 1,
        title: "Frontend Developer",
        subtitle: "TechSolutions",
        description: "Colabora con un equipo dinámico para desarrollar interfaces de usuario atractivas y funcionales que impulsen el éxito de nuestros clientes en el mundo digital.",
        date: "Nov 2023 ",
    },
    {
        id: 2,
        title: "Creador de Experiencias Digitales",
        subtitle: "PixelCrafters",
        description: "Trabaja en proyectos emocionantes que desafían los límites de la creatividad y la tecnología. Únete a nosotros mientras creamos experiencias digitales cautivadoras que inspiran y cautivan a nuestros usuarios.",
        date: "May 2021",
    },
    {
        id: 3,
        title: "Especialista en Desarrollo Frontend",
        subtitle: "CodeForge Solutions",
        description: "Como desarrollador frontend, tendrás la oportunidad de colaborar en proyectos diversos y desafiantes que te permitirán expandir tus habilidades y dejar tu huella en el mundo digital.",
        date: "Ago 2019",
    },
    {
        id: 4,
        title: "Prácticas Grado",
        subtitle: "WebWizards Inc.",
        description: "Únete a nosotros mientras creamos sitios web y aplicaciones interactivas que sorprenden y deleitan a nuestros clientes. Si tienes pasión por el diseño y la programación, y disfrutas colaborar en un entorno creativo, ¡queremos conocerte!        ",
        date: "Mar 2018",
    },
]

export const dataCounter = [
    {
        id: 0,
        endCounter: 10,
        text: "Años de experiencia",
        lineRight: true,
        lineRightMobile: true,
    },
    {
        id: 1,
        endCounter: 80,
        text: "Clientes satisfechos",
        lineRight: true,
        lineRightMobile: false,
    },
    {
        id: 2,
        endCounter: 220,
        text: "Proyectos finalizados",
        lineRight: true,
        lineRightMobile: true,
    },
    {
        id: 3,
        endCounter: 30,
        text: "Premios ganadores",
        lineRight: false,
        lineRightMobile: false,
    },
];

export const serviceData = [

    // {
    //     icon: <Pencil />,
    //     title: "Diseño web",
    //     description: "Diseño creativo y profesional de interfaces web intuitivas y atractivas, adaptado a tus necesidades",
    // },
    {
        icon: <Computer />,
        title: "Desarrollo web",
        description: "Diseño y desarrollo de sitios web a medida, adaptados a tus necesidades",
    },
    {
        icon: <CodeXml />,
        title: "Plataforma Saas",
        description: "Creación de plataformas a medida con funcionalidades avanzadas para optimizar al máximo tu trabajo",
    },
    {
        icon: <TabletSmartphone />,
        title: "Aplicaciones Móvil",
        description: "Realizamos Aplicaciones Móvil a medida. Somos expertos en realizar aplicaciones complejas.",
    },
    {
        icon: <Camera />,
        title: "Fotografía",
        description: "Expertos en retratos, estudio y fotografía deportiva. Capturamos la esencia y la emoción en cada imagen.",
    },
    {
        icon: <Video />,
        title: "Vídeo",
        description: "Creamos vídeos cautivadores que capturan la atención del espectador, garantizando un impacto memorable.",
    },
    {
        icon: <Instagram />,
        title: "Contenido",
        description: "Con múltiples empresas bajo nuestra gestión, creamos contenido que emociona y cautiva a tu audiencia.",
    },
    {
        icon: <Rocket />,
        title: "SEO",
        description: "Optimización de tu presencia en línea mediante estrategias de SEO avanzadas, ",
    },

];

export const dataPortfolio = [
    {
        id: 1,
        title: "Anerco",
        image: "/anerco.png",
        urlGithub: "#!",
        urlDemo: "https://anerco.es",
    },
    {
        id: 2,
        title: "BeGoat",
        image: "/begoat.png",
        urlGithub: "#!",
        urlDemo: "https://begoat3x3.com",
    },
    {
        id: 3,
        title: "LaLiga3x3",
        image: "/Laliga3x3.png",
        urlGithub: "#!",
        urlDemo: "https://laliga3x3.com/",
    },

    {
        id: 4,
        title: "Isorga",
        image: "/isorga.png",
        urlGithub: "#!",
        urlDemo: "https://isorga.com/",
    },
    {
        id: 5,
        title: "Aniverse",
        image: "/aniverse.png",
        urlGithub: "#!",
        urlDemo: "https://apps.apple.com/es/app/aniverse/id6503652645",
    },
    {
        id: 6,
        title: "Itemiza",
        image: "/itemiza.png",
        urlGithub: "#!",
        urlDemo: "https://apps.apple.com/es/app/itemiza/id6503139474",
    },
    {
        id: 7,
        title: "Isorga Móvil",
        image: "/isorgaMovil.png",
        urlGithub: "#!",
        urlDemo: "https://apps.apple.com/es/app/isorga/id6599842102",
    },
    // {
    //     id: 8,
    //     title: "Itemiza",
    //     image: "/itemiza.png",
    //     urlGithub: "#!",
    //     urlDemo: "https://apps.apple.com/es/app/itemiza/id6503139474",
    // },

    // {
    //     id: 7,
    //     title: "Dark Web ",
    //     image: "/image-7.jpg",
    //     urlGithub: "#!",
    //     urlDemo: "#!",
    // },
    // {
    //     id: 8,
    //     title: "E-commerce web",
    //     image: "/image-8.jpg",
    //     urlGithub: "#!",
    //     urlDemo: "#!",
    // }
];

export const dataTestimonials = [
    {
        id: 1,
        name: "George Snow",
        description:
            "¡Increíble plataforma! Los testimonios aquí son genuinos y me han ayudado a tomar decisiones informadas. ¡Altamente recomendado!",
        imageUrl: "/profile1.png",
    },
    {
        id: 2,
        name: "Juan Pérez",
        description:
            "Me encanta la variedad de testimonios disponibles en esta página. Es inspirador ver cómo otras personas han superado desafíos similares a los míos. ¡Gracias por esta invaluable fuente de motivación!",
        imageUrl: "/profile2.png",
    },
    {
        id: 3,
        name: "María García",
        description:
            "Excelente recurso para obtener opiniones auténticas sobre diferentes productos y servicios. Me ha ayudado mucho en mis compras en línea. ¡Bravo por este sitio!",
        imageUrl: "/profile3.png",
    },
    {
        id: 4,
        name: "Laura Snow",
        description:
            "¡Qué descubrimiento tan fantástico! Los testimonios aquí son honestos y detallados. Me siento más seguro al tomar decisiones después de leer las experiencias compartidas por otros usuarios.",
        imageUrl: "/profile4.png",
    },
    {
        id: 5,
        name: "Carlos Sánchez",
        description:
            "Una joya en la web. Los testimonios son fáciles de encontrar y están bien organizados. ¡Definitivamente mi destino número uno cuando necesito referencias confiables!",
        imageUrl: "/profile5.png",
    },
    {
        id: 6,
        name: "Antonio Martínez",
        description:
            "¡Fantástico recurso para aquellos que buscan validación antes de tomar decisiones importantes! Los testimonios aquí son veraces y realmente útiles. ¡Gracias por simplificar mi proceso de toma de decisiones!",
        imageUrl: "/profile6.png",
    },
];